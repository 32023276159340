const $ = jQuery;

$('#membership-add-to-cart').on('click',   async(e) => {
    await handleDonationSubmit(e);
})

// Membership and donation logic
$('.membership-radio').on('click', function (e) {
    const membership_id = $(e.target).prop('id');
    // set the container id to match the selected membership
    $('#spektrix-membership-container').attr('membership-id', membership_id);
})

const postFund  = async (amount, client, fundId, URL) => {
    const response = await fetch(URL, {
        method: 'POST',
        // MUST INCLUDE CREDENTIALS OTHERWISE CART WILL REMAIN EMPTY
        credentials: 'include',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify([{
            amount: amount,
            fundId: fundId,
            isAnonymous: true,

        }])
    });
    return response;
}

const handleDonationSubmit = async(e) => {
    e.preventDefault()
    e.stopImmediatePropagation()

    const submitButton = $('#membership-add-to-cart');
    const amount = parseFloat($('#membership-donation-amount').val()).toFixed(2);
    const clientName = submitButton.data('client-name');
    const domainName =  submitButton.data('custom-domain');
    const fundId = submitButton.data('fund-id');
    const redirectTo = submitButton.data("redirect-url");
    const URL = "https://"  + domainName +  "/" + clientName + "/api/v3/basket/donations";

    if(amount && amount > 0) {
        // if there's an amount present we want to post the donation amount to the correct fund,
        // otherwise we simply fire the submit button
        const response = await postFund(amount, clientName, fundId, URL);
        if (response.status === 200) {
            // trigger the actual submit button
            $("#hidden-form-submit").trigger('click');
        }
    } else {
        $("#hidden-form-submit").trigger('click');
    }
    // window.location = redirectTo;
}